<template>
  <table class="table">
    <thead>
      <tr>
        <th>Champions Name</th>
        <th>Champion Page</th>
        <th>Champion Collection</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="champ in champList" :key="champ">
        <td>{{ champ.SubCauseName }}</td>
        <td>{{ champ.ReferralCode }}</td>
        <td>KES {{ champ.amount }}</td>
      </tr>
    </tbody>
  </table>
  {{ champList }}
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    campaign_id: {
      default: 0,
      type: Number,
    },
  },
  async created() {
    
  },

};
</script>

